import { LoginPage } from "../features/LoginPage";
import { useFlashMessage } from "../components/FlashMessage";
import { useEffect } from "react";

const NotFoundErrorPage = () => {
  const { showMessage } = useFlashMessage();
  useEffect(() => {
    showMessage("error", "お探しのページが見つかりません。");
    // マウント時にエラー表示したいだけなのでshowMessageはdepsから除外している
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoginPage />;
};

export default NotFoundErrorPage;
